input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

$figma-20px: 8px;
$figma-36px: 14px;
$figma-40px: 16px;
$figma-120px: 48px;
$figma-140px: 56px;

$text-size-figma-32: 12px;
$text-size-figma-40: 16px;
$text-size-figma-44: 18px;
$text-size-figma-48: 20px;
$text-size-figma-64: 26px;
$text-size-figma-96: 38px;
$text-color-grey: #a6a5c0;

$border-radius-figma-20: 8px;
$border-radius-figma-140: 56px;

$base_height_button: 56px;
$base_height_input: 48px;
$button_font_size: $figma-40px;

$logo-image-top: -236px;
$logo-image-size: 966px;

.text-grey {
  color: $text-color-grey;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.meta-button_base {
  width: 100%;
  height: $base_height_button;
  border-radius: $border-radius-figma-140;
  color: white;
  font-size: $button_font_size;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
}

.meta_button_purple {
  @extend .meta-button_base;
  background: linear-gradient(90deg, #b048ff, #8147ff);
}

.meta_button_orange {
  @extend .meta-button_base;
  background: linear-gradient(90deg, #ee7228, #fc4f56);
}

.meta-input {
  font-size: $figma-40px;
  height: $figma-120px;
  border: none;
  border-radius: $border-radius-figma-20;
  background-color: #494879;
  color: white;
  box-shadow: none;
}

.meta_token_input {
  width: 100%;
  height: $figma-120px;
  padding: 10px 40px 10px 10px;
  border: none;
  border-radius: 10px;
  background-color: #494879;
  color: white;
  font-size: 16px;
  outline: none;
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.error-message-placeholder {
  min-height: 20px;
}

.image-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: $logo-image-top;
  z-index: -2;
}

.logo-image {
  width: $logo-image-size;
  height: $logo-image-size;
}

.main-container {
  max-width: 430px;
  padding-left: 30px;
  padding-right: 30px;
}
